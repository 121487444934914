import CellFields from "@/components/CellFields";
import SkeletonPage from "@/components/SkeletonPage";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import { getData } from "@/lib/fetchDataClient";
import { useStore } from "@/lib/store";
import useToolData from "@/lib/useTool";
import { deepClone } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { z } from "zod";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { FormSchema } from "./formSchema";
import { setData } from "@/lib/setDataClient";

type FormSchemaType = z.infer<typeof FormSchema>;

export default function IndexPage({ params }: { params: { id: string } }) {
  const id = params.id;
  const tool = useToolData(id);
  const [fileName, setFileName] = useState("");
  const [isLoading, setLoading] = useState(true);
  const { toast } = useToast();
  const token = useStore((state) => state.token);
  const [useNamedRanges, setNamedRanges] = useState<Record<string, any>>({});
  const [useNamedRangeRegObject, setNamedRangeRefObject] = useState({});

  const [designerData, setDesignerData] = useState();
  const { control, setValue, trigger, formState, ...form } = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      //@ts-ignore
      inputs: [{ sheet: "", cell: "", label: "", type: "cell", namedRange: "" }],
      file: "",
      //@ts-ignore
      outputs: [{ sheet: "", cell: "", label: "", type: "cell", namedRange: "" }],
    },
  });

  const { fields: inputFields, append: appendInput, remove: removeInput } =
    useFieldArray({
      control,
      name: "inputs",
    });
  const { fields: outputFields, append: appendOutput, remove: removeOutput } =
    useFieldArray({
      control,
      name: "outputs",
    });

  const watchedInputs = useWatch({ control, name: "inputs" });
  const watchedOutputs = useWatch({ control, name: "outputs" });

  const validateFields = useCallback(() => {
    const isFieldValid = (field: any) =>
      //@ts-ignore
      (field.type === "cell" && field.sheet && field.cell) ||
      //@ts-ignore
      (field.type === "namedRange" && field.namedRange);

    return watchedInputs.every(isFieldValid) && watchedOutputs.every(isFieldValid);
  }, [watchedInputs, watchedOutputs]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (file) {
        setFileName(file.name);
        const reader = new FileReader();
        reader.onloadend = () => {
          setValue("file", reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    },
    [setValue]
  );

  useEffect(() => {
    const fetchData = async () => {
      const res = await getData(`/tools/designer/${id}`);
      setDesignerData(res.designerData);

      const namedRanges = await getData(`/tools/named-ranges/${id}`);

      if (tool) {
        const { inputs, outputs, file } = tool.details[0];
        setValue("inputs", inputs || []);
        setValue("outputs", outputs || []);
        setValue("file", file || "");

        // Map named ranges to inputs and outputs //@ts-ignore
        //@ts-ignore
        const mappedInputs = inputs.map((input: any) => {
          const namedRange = namedRanges.inputs.find((nr: any) => nr.address === input.cell);
          return namedRange ? { ...input, namedRange: namedRange.name, type: "namedRange" } : input;
        });
        //@ts-ignore
        const mappedOutputs = outputs.map((output: any) => {
          const namedRange = namedRanges.outputs.find((nr: any) => nr.address === output.cell);
          return namedRange ? { ...output, namedRange: namedRange.name, type: "namedRange" } : output;
        });

        setValue("inputs", mappedInputs);
        setValue("outputs", mappedOutputs);
      }

      if (namedRanges) {
        const { inputs, outputs } = namedRanges;
        setNamedRangeRefObject(namedRanges)
        setNamedRanges({
          inputs: inputs.map((i: any) => i.name),
          outputs: outputs.map((o: any) => o.name),
        });
      }
    };

    fetchData();
  }, [id, setValue, tool]);

  useEffect(() => {
    if (inputFields.length > 0) {
      setLoading(false);
    }
  }, [inputFields]);

  const updateDesignerData = async (formData: FormSchemaType) => {
    const updatedData = deepClone(designerData);
    const updateData = (formSection: any[], toolSection: any[] | undefined) => {
      formSection.forEach((item) => {
        const originalItem = toolSection?.find(
          (oi) => oi.sheet === item.sheet && oi.cell === item.cell
        );
        if (originalItem && originalItem.label !== item.label) {
          updatedData.content = updatedData.content.map((contentItem: any) =>
            contentItem.type === originalItem.label ? { ...contentItem, type: item.label } : contentItem
          );
          for (const zoneKey in updatedData.zones) {
            updatedData.zones[zoneKey] = updatedData.zones[zoneKey].map((zoneItem: any) =>
              zoneItem.type === originalItem.label ? { ...zoneItem, type: item.label } : zoneItem
            );
          }
        }
      });
    };

    if (tool) {
      updateData(formData.inputs, tool.details[0]?.inputs);
      updateData(formData.outputs, tool.details[0]?.outputs);
    }

    await setData({
      path: `/tools/designer/${id || ""}`,
      formData: JSON.stringify({ data: { ...updatedData } }),
      method: "POST",
    });
  };

  const handleSubmit = async (data: FormSchemaType) => {

    setLoading(true);

    try {
      const processedInputs = data.inputs.map((input) => {
        if (input.type === "namedRange" && input.namedRange) {//@ts-ignore
          const range = useNamedRangeRegObject.inputs.find((nr: any) => nr.name === input.namedRange);

          if (range) {
            return { ...input, cell: range.address, value: range.value };
          }
        }
        return input;
      });




      const processedOutputs = data.outputs.map((output) => {
        if (output.type === "namedRange" && output.namedRange) {
          const range = useNamedRanges.outputs.find((nr: any) => nr.name === output.namedRange);
          if (range) {
            return { ...output, cell: range.address, value: range.value };
          }
        }
        return output;
      });

      const formData = {
        ...data,
        inputs: processedInputs,
        outputs: processedOutputs,
      };



      if (tool) {
        await setData({
          path: `/tools/${tool.details[0].uuid || ""}`,
          formData: JSON.stringify(formData),
          method: "POST",
        });

        const updatedToolDetails = { ...tool.details[0], inputs: formData.inputs, outputs: formData.outputs };//@ts-ignore
        useStore.setState({ selectedTool: { ...tool, details: [updatedToolDetails] } });

        toast({ title: "Tool Cells Updated", description: "You have successfully updated the tool." });
      }

      if (acceptedFiles[0]) {
        const fileFormData = new FormData();
        fileFormData.append("file", acceptedFiles[0]);
        const requestOptions = {
          method: "PUT",
          headers: { Authorization: token || "" },
          body: fileFormData,
        };
        const response = await fetch(`${process.env.REACT_APP_BASE_URL || "https://app.toolcrafter.app/v1"}/tools/upload/${id}`, requestOptions);
        if (response.ok) {
          toast({ title: "File Uploaded", description: "The spreadsheet has been successfully updated." });
        } else {
          const uploadRes = await response.json();
          toast({ title: "Upload Failed", description: `Failed to update the spreadsheet: ${uploadRes.message}` });
        }
      }

      await updateDesignerData(formData);
    } catch (error) {
      toast({ title: "Update Failed", description: `An error occurred: ${(error as Error).message || "Unknown error"}` });
    }

    setLoading(false);
  };

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: 1, accept: { "application/vnd.ms-excel": [".xls"], "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"] } });

  const dropzoneStyles = `border-2 ${isDragActive ? "w-1/2 border-blue-400 bg-blue-100" : "border-gray-300"} border-dashed rounded-md p-6 text-center transition-all w-1/2`;

  const clearFile = () => {
    setFileName("");
    setValue("file", "");
  };

  if (isLoading || !tool || (tool && !tool.details[0].sheets)) {
    return <SkeletonPage />;
  }

  return (//@ts-ignore
    <Form {...form} setValue={setValue}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="w-full space-y-3">
        <div className="flex flex-col lg:flex-row">
          <div className="w-full pr-2 mb-6 lg:mb-0">
            <CellFields //@ts-ignore
              fields={inputFields}
              append={appendInput}
              remove={removeInput}
              //@ts-ignore
              control={control}
              namePrefix="inputs"
              form={form}
              title="Input Cells"
              setValue={setValue}
              tool={tool} //@ts-ignore
              namedRanges={useNamedRanges}
            />
          </div>
          <div className="w-full pr-2 mb-6 lg:mb-0">
            <CellFields //@ts-ignore
              fields={outputFields}
              append={appendOutput}
              remove={removeOutput}
              setValue={setValue}

              //@ts-ignore
              control={control}
              namePrefix="outputs"
              form={form}
              title="Output Cells"
              tool={tool} //@ts-ignore
              namedRanges={useNamedRanges}
            />
          </div>
        </div>
        <h5 className="text-lg font-semibold tracking-tight mr-3 mb-6">
          Spreadsheet
        </h5>
        {fileName && (
          <div className="flex items-center space-x-2 mb-3">
            <span className="text-sm">{fileName}</span>
            <button type="button" onClick={clearFile} className="text-blue-500 hover:text-blue-600">Clear</button>
          </div>
        )}
        <div {...getRootProps()} className={dropzoneStyles}>
          <input {...getInputProps()} />
          {isDragActive ? <p>Drop the files here ...</p> : <p>Drag/drop a spreadsheet here, or click to select a spreadsheet (.xls)</p>}
        </div>

        {(formState.errors.inputs as any)?.outputs?.message && (
          <p className="text-red-500">
            {(formState.errors.inputs as any)?.outputs?.message}
          </p>
        )}

        <div className="flex py-5">
          <Button loading={isLoading} disabled={isLoading || !validateFields()} type="button" onClick={() => form.handleSubmit(handleSubmit)()}>
            Submit
          </Button>
        </div>
      </form>
    </Form>
  );
}
