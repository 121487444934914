import React, { useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../globals.css";

import { SidebarNav } from "@/components/SidebarNav";
import {
  IdCardIcon,
  ImageIcon,
  MixIcon,
  RocketIcon,
} from "@radix-ui/react-icons";
import { useStore } from "@/lib/store";
import useToolData from "@/lib/useTool";

interface WorkspaceLayoutProps {
  children: React.ReactNode;
  params?: { id: string };
}

export default function WorkspaceLayout({
  children,
  params,
}: WorkspaceLayoutProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  //@ts-ignore
  const token = useStore((state) => state.token);
  //@ts-ignore
  const currentPlan = useStore((state) => state.currentPlan);

  useLayoutEffect(() => {
    if (!token || token === "") {
      if (
        pathname !== "/forgot-password" &&
        !pathname.includes("/register") && pathname !== '/'
      ) {
        navigate("/");
      }
    }
  }, [token, navigate, pathname]);

  let id: string | null = params?.id || pathname.split("/")[2];
  if (id === "paymentsuccess") {
    id = null;
  }
  const dontShowSidebar =
    pathname === "/" ||
    pathname.includes("register") ||
    pathname.includes("forgot-password") ||
    pathname.includes("preview");
  const dontFetchTool =
    pathname === "/workspace" ||
    pathname.includes("media") ||
    pathname.includes("paymentredirect") ||
    pathname.includes("plans") ||
    pathname.includes("billing");
  const tool = useToolData(id, dontFetchTool);


  const isPreview = pathname.split("/")[3] === `preview`;
  const isDesign = pathname.split("/")[3] === `design`;
  let sidebarNavItems = [];
  if (
    pathname === "/workspace" ||
    pathname.includes("media") ||
    pathname.includes("paymentredirect") ||
    pathname.includes("plans") ||
    pathname.includes("billing")
  ) {
    sidebarNavItems = [
      {
        title: "All Tools",
        href: "/workspace",
        icon: <MixIcon />,
      },
      {
        title: "Media",
        href: "/workspace/media",
        icon: <ImageIcon />,
      },
      {
        title: "Plans",
        href: "/workspace/plans",
        icon: <RocketIcon />,
      },
      {
        title: "Billing",
        href: "/workspace/billing",
        icon: <IdCardIcon />,
      },
    ];
  } else {
    const id = pathname.split("/")[2];
    sidebarNavItems = [
      {
        title: "General",
        href: `/workspace/${id}/general`,
      },
      {
        title: "Source",
        href: `/workspace/${id}/source`,
      },
      {
        title: "Design",
        href: `/workspace/${id}/design`,
      },
    ];

    if (tool?.details[0]?.isOwner) {
      if (
        currentPlan?.planName === "Basic" ||
        currentPlan?.planName === "Advanced"
      ) {
        sidebarNavItems.push({
          title: "Custom Domain",
          href: `/workspace/${id}/customDomain`,
        });
      }
      sidebarNavItems.push({
        title: "Collaborators",
        href: `/workspace/${id}/collaborators`,
      });
      // sidebarNavItems.push({
      //   title: "Premium",
      //   href: `/workspace/${id}/premium`,
      // });
    }
  }


  if (dontShowSidebar) {
    return <>{children}</>;
  }
  return (
    <>
      <div className="container flex justify-items-start w-[100%] align-top pt-3">
        {!isDesign && <SidebarNav items={sidebarNavItems} />}
        <div className="container w-full md:pr-0">{children}</div>
      </div>
    </>
  );
}
